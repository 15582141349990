import React, { useState } from "react";
import { Box, Typography, Button, Container, Grid, Paper, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { colors } from "../lib/theme";
import { useAuth } from "../../shared/useAuth";
import { Helmet } from "react-helmet";
import Carousel from 'react-material-ui-carousel';
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MemoryIcon from "@mui/icons-material/Memory";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .main-content {
    max-width: 100%
  }
  #react-root {
    padding: 0;
  }
`;

import hero1 from '../../images/hero1.jpg';
import hero2 from '../../images/hero2.jpg';
import hero3 from '../../images/hero3.jpg';
import hero4 from '../../images/hero4.jpg';
import hero5 from '../../images/hero5.jpg';
import hero6 from '../../images/hero6.jpg';
import hero7 from '../../images/hero7.jpg';

const testimonials = [
  {
    name: "Sarah J.",
    avatar: "https://randomuser.me/api/portraits/women/1.jpg",
    text: "Golden Record helped me preserve my grandmother's stories for future generations. Her voice, laughter, and wisdom are now a cherished family treasure. It's truly priceless!"
  },
  {
    name: "Michael T.",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    text: "I gifted Golden Record to my parents for their 50th anniversary. Now we have a beautiful collection of their life stories, advice, and memories. It's brought our family even closer together."
  },
  {
    name: "Emily R.",
    avatar: "https://randomuser.me/api/portraits/women/2.jpg",
    text: "The app is incredibly user-friendly, even for my tech-shy grandparents. The quality of the recordings is crystal clear, capturing every nuance of their voices. Highly recommend!"
  },
  {
    name: "David L.",
    avatar: "https://randomuser.me/api/portraits/men/2.jpg",
    text: "As a family historian, Golden Record has been a game-changer. I've recorded interviews with relatives, preserving stories that would have otherwise been lost. It's an invaluable tool for anyone interested in genealogy."
  },
  {
    name: "Olivia P.",
    avatar: "https://randomuser.me/api/portraits/women/3.jpg",
    text: "I used Golden Record to create a surprise audio gift for my sister's wedding. Hearing messages from loved ones who couldn't attend brought tears to everyone's eyes. It made the day even more special."
  },
  {
    name: "James H.",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
    text: "As someone who's always struggled to keep a written journal, Golden Record has been perfect. I can easily record my thoughts and experiences, creating an audio diary that I know I'll treasure in years to come."
  }
];

const heroImages = [
  hero1,
  hero2,
  hero3,
  hero4,
  hero5,
  hero6,
  hero7,
];

const Home = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  if (user && !loading) {
    navigate("/dashboard");
  }

  return (
    !loading && (
      <Box sx={{ bgcolor: colors.navy, minHeight: '100vh', color: colors.white }}>
        <GlobalStyle />
        <Helmet>
          <title>Golden Record - Preserve Your Memories | Audio Legacy App</title>
          <meta
            name="description"
            content="Create audio gifts and preserve memories for your loved ones with Golden Record. Record, share, and cherish family stories for generations to come."
          />
          <meta name="keywords" content="audio legacy, family memories, oral history, digital storytelling, memory preservation" />
          <link rel="canonical" href="https://app.goldenrecord.app" />
          <meta property="og:title" content="Golden Record - Preserve Your Memories | Audio Legacy App" />
          <meta property="og:description" content="Create audio gifts and preserve memories for your loved ones with Golden Record. Record, share, and cherish family stories for generations to come." />
          <meta property="og:image" content="https://app.goldenrecord.app/hero.jpg" />
          <meta property="og:url" content="https://app.goldenrecord.app" />
        </Helmet>

        {/* Hero Section */}
        <Box sx={{ bgcolor: colors.darkNavy }}>
          <Container maxWidth="lg">
            <Box sx={{ py: 8 }}>
              <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Typography variant="h2" sx={{ color: colors.gold, fontWeight: 'bold', mb: 2 }} style={{ fontSize: '2.5rem' }}>
                    Preserve Their Legacy
                  </Typography>
                  <Typography variant="h5" sx={{ mb: 4 }}>
                    Record and share your most precious memories with Golden Record
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    <Button
                      variant="emphasized"
                      size="large"
                      component={Link}
                      to="/questionnaire"
                    >
                      Gift Golden Record
                    </Button>
                    <Button
                      variant="secondary"
                      size="large"
                      component={Link}
                      to="/register"
                    >
                      Create Account
                    </Button>
                  </Box>
                  <Typography variant="body2" sx={{ mt: 4, color: colors.white }}>
                    Already have an account? <Link to="/login" style={{ color: colors.gold }}>Log in here</Link>.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Carousel
                    autoPlay={true}
                    animation="fade"
                    indicators={true}
                    navButtonsAlwaysVisible={true}
                    interval={6000}
                    navButtonsProps={{
                      style: {
                        backgroundColor: colors.gold,
                        color: colors.navy
                      }
                    }}
                  >
                    {heroImages.map((image, index) => (
                      <Box
                        key={index}
                        component="img"
                        src={image}
                        alt={`Golden Record App - Hero ${index + 1}`}
                        sx={{
                          width: '100%',
                          height: '500px',
                          objectFit: 'cover',
                          borderRadius: 2,
                          boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                        }}
                      />
                    ))}
                  </Carousel>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>

        {/* Why Choose Golden Record Section */}
        <Box sx={{ bgcolor: colors.navy }}>
          <Container maxWidth="lg">
            <Box sx={{ py: 8 }}>
              <Typography variant="h3" sx={{ color: colors.gold, textAlign: 'center', mb: 6 }}>
                Why Choose Golden Record?
              </Typography>
              <Grid container spacing={4}>
                {[
                  { icon: <PhoneIphoneIcon sx={{ fontSize: 40 }} />, title: "Easy to Use", description: "Our intuitive app makes recording and sharing memories a breeze." },
                  { icon: <MemoryIcon sx={{ fontSize: 40 }} />, title: "Preserve Memories", description: "Capture stories, wisdom, and experiences for future generations." },
                  { icon: <FavoriteIcon sx={{ fontSize: 40 }} />, title: "Meaningful Gift", description: "Give the gift of preserved memories to your loved ones." }
                ].map((feature, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <Paper sx={{ p: 3, height: '100%', bgcolor: 'rgba(255,255,255,0.05)', color: colors.white }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        {feature.icon}
                        <Typography variant="h6" sx={{ my: 2, color: colors.gold }}>{feature.title}</Typography>
                        <Typography>{feature.description}</Typography>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>

        {/* What Our Users Say Section */}
        <Box sx={{ bgcolor: colors.darkNavy }}>
          <Container maxWidth="lg">
            <Box sx={{ py: 8 }}>
              <Typography variant="h3" sx={{ color: colors.gold, textAlign: 'center', mb: 6 }}>
                What Our Users Say
              </Typography>
              <Carousel
                autoPlay={false}
                animation="slide"
                indicators={true}
                navButtonsAlwaysVisible={true}
                navButtonsProps={{
                  style: {
                    backgroundColor: colors.gold,
                    color: colors.navy
                  }
                }}
              >
                {testimonials.map((item, index) => (
                  <Paper key={index} sx={{ px: 10, py: 4, bgcolor: 'rgba(255,255,255,0.05)', color: colors.white }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Avatar src={item.avatar} sx={{ width: 60, height: 60, mr: 2 }} />
                      <Typography variant="h6">{item.name}</Typography>
                    </Box>
                    <Typography variant="body1" sx={{ fontStyle: 'italic' }}>"{item.text}"</Typography>
                  </Paper>
                ))}
              </Carousel>
            </Box>
          </Container>
        </Box>

        {/* Ready to Preserve Your Memories Section */}
        <Box sx={{ bgcolor: colors.navy }}>
          <Container maxWidth="lg">
            <Box sx={{ py: 8, textAlign: 'center' }}>
              <Typography variant="h3" sx={{ color: colors.gold, mb: 4 }}>
                Ready to Preserve Your Memories?
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
                <Button
                  variant="emphasized"
                  size="large"
                  component={Link}
                  to="/questionnaire"
                >
                  Get Started
                </Button>
                <Button
                  variant="secondary"
                  size="large"
                  component={Link}
                  to="/login"
                >
                  Log In
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>

      </Box>
    )
  );
};

export default Home;
